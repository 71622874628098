/* For use in src/lib/core/theming/_palette.scss */
$visu-patrimoine-palette: (
  50: #eae6f5,
  100: #cbc0e5,
  200: #a896d4,
  300: #856cc2,
  400: #6b4db5,
  500: #804180,
  600: #4a28a0,
  700: #402297,
  800: #371c8d,
  900: #27117d,
  A100: #beb0ff,
  A200: #947dff,
  A400: #6a4aff,
  A700: #5430ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
