@font-face {
  font-family: 'FSSinclair-regular';
  src: url('/assets/fonts/FSSinclair-regular/FSSinclair.woff2') format('woff2'),
  url('/assets/fonts/FSSinclair-regular/FSSinclair.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FSSinclair';
  src: url('/assets/fonts/FSSinclair/FSSinclair-Italic.eot');
  src: url('/assets/fonts/FSSinclair/FSSinclair-Italic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/FSSinclair/FSSinclair-Italic.woff2') format('woff2'),
  url('/assets/fonts/FSSinclair/FSSinclair-Italic.woff') format('woff'),
  url('/assets/fonts/FSSinclair/FSSinclair-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TheSans';
  src: url('/assets/fonts/TheSans/thesans-regular_44265-webfont.woff2') format('woff2'),
  url('/assets/fonts/TheSans/thesans-regular_44265-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans OT5 Plain';
  src: url('/assets/fonts/TheSans/TheSans-OT5PlainItalic.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT5PlainItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TheSans OT3';
  src: url('/assets/fonts/TheSans/TheSans-OT3LightItalic.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT3LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'TheSans OT2';
  src: url('/assets/fonts/TheSans/TheSans-OT2ExtraLight.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT2ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans OT8';
  src: url('/assets/fonts/TheSans/TheSans-OT8ExtraBoldItalic.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT8ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'TheSans OT7';
  src: url('/assets/fonts/TheSans/TheSans-OT7Bold.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT7Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans OT9';
  src: url('/assets/fonts/TheSans/TheSans-OT9BlackItalic.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT9BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'TheSans OT7';
  src: url('/assets/fonts/TheSans/TheSans-OT7BoldItalic.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT7BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'TheSans OT7 Bold';
  src: url('/assets/fonts/TheSans/TheSans-OT7Bold.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT7Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'TheSans OT5 Plain';
  src: url('/assets/fonts/TheSans/TheSans-OT5Plain.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT5Plain.woff') format('woff');
}

@font-face {
  font-family: 'TheSans OT6';
  src: url('/assets/fonts/TheSans/TheSans-OT6SemiBold.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT6SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans OT4 SemiLight';
  src: url('/assets/fonts/TheSans/TheSans-OT4SemiLightItalic.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT4SemiLightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'TheSans OT9';
  src: url('/assets/fonts/TheSans/TheSans-OT9Black.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT9Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans OT8';
  src: url('/assets/fonts/TheSans/TheSans-OT8ExtraBold.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT8ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans OT6';
  src: url('/assets/fonts/TheSans/TheSans-OT6SemiBoldItalic.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT6SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'TheSans OT3';
  src: url('/assets/fonts/TheSans/TheSans-OT3Light.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT3Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans OT4 SemiLight';
  src: url('/assets/fonts/TheSans/TheSans-OT4SemiLight.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT4SemiLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TheSans OT2';
  src: url('/assets/fonts/TheSans/TheSans-OT2ExtraLightItalic.woff2') format('woff2'),
  url('/assets/fonts/TheSans/TheSans-OT2ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

